import axios from "axios";
import { BlobServiceClient } from "@azure/storage-blob";

// Enter your storage account name and sas token
const account = "vagtstorage";
const sas =
  "?sv=2019-12-12&ss=bfqt&srt=sco&sp=rwdlacupx&se=2031-02-16T18:22:52Z&st=2021-02-16T10:22:52Z&spr=https&sig=Shuphvg%2B1Q60edEUSg8I63cijduFAQSa1DKKXc7pU08%3D";

const blobServiceClient = new BlobServiceClient(
  `https://${account}.blob.core.windows.net${sas}`
);

export default {
  getDawaDataAndSave({ commit, dispatch }) {
    return axios
      .get("https://dawa.aws.dk/postnumre?format=json")
      .then(function(result) {
        commit("saveDawaPost", result.data);
        return dispatch("saveToBlobStorage", {
          data: result.data,
          blobName: "dawa",
        });
      });
  },
  saveInfoContent({ commit, dispatch }, data) {
    commit("setInfoTitelAndContent", data);
    return dispatch("saveToBlobStorage", {
      data,
      blobName: "info",
    });
  },
  async saveToBlobStorage({ state }, data) {
    if (state.containerName != null) {
      const containerClient = blobServiceClient.getContainerClient(
        state.containerName
      );
      // console.log(containerClient);

      const content = JSON.stringify(data.data);
      const blockBlobClient = containerClient.getBlockBlobClient(data.blobName);
      const uploadBlobResponse = await blockBlobClient.upload(
        content,
        content.length
      );
      // console.log(`Upload block blob ${data.blobName} successfully`,uploadBlobResponse.requestId);
      return "Data er gemt!";
    }
  },
  async listContainers({ commit, dispatch }) {
    let i = 1;
    let iter = blobServiceClient.listContainers();
    let containerItem = await iter.next();
    while (!containerItem.done) {
      // console.log(`Container ${i++}: ${containerItem.value.name}`);
      if (containerItem.value.name == "data") {
        // console.log("Found it");
        commit("setContainerName", containerItem.value.name);
        dispatch("listBlobs", containerItem.value.name);
      }
      containerItem = await iter.next();
    }

    if (containerItem.done) {
      setTimeout(() => {
        commit("setDataLoaded", true);
      }, 700);
    }
  },
  async listBlobs({ dispatch }, containerName) {
    const containerClient = blobServiceClient.getContainerClient(containerName);

    let i = 1;
    let blobs = containerClient.listBlobsFlat();
    for await (const blob of blobs) {
      var blobName = blob.name;
      dispatch("getBlobData", {
        containerName,
        blobName,
      });
      // console.log(`Blob ${i++}: ${blobName}`);
    }
  },
  async getBlobData({ commit, dispatch }, data) {
    const containerClient = blobServiceClient.getContainerClient(
      data.containerName
    );
    const blobClient = containerClient.getBlobClient(data.blobName);

    const downloadBlockBlobResponse = await blobClient.download();
    const downloaded = await dispatch(
      "blobToString",
      await downloadBlockBlobResponse.blobBody
    );

    if (data.blobName == "persons") {
      // console.log("Downloaded blob content", JSON.parse(downloaded));
      // console.log("Found - Saving persons app storage");
      commit("saveData", JSON.parse(downloaded));
    }

    if (data.blobName == "dawa") {
      // console.log("Downloaded blob content", JSON.parse(downloaded));
      // console.log("Found - Saving dawa to app storage");
      commit("saveDawaPost", JSON.parse(downloaded));
    }

    if (data.blobName == "info") {
      // console.log("Downloaded blob content", JSON.parse(downloaded));
      // console.log("Found - Saving info content to app storage");
      commit("setInfoTitelAndContent", JSON.parse(downloaded));
    }

    if (data.blobName == "work") {
      // console.log("Downloaded blob content", JSON.parse(downloaded));
      // console.log("Found - Saving work content to app storage");
      commit("saveWorkPlanData", JSON.parse(downloaded));
    }

    if (data.blobName == "login") {
      // console.log("Downloaded blob content", JSON.parse(downloaded));
      // console.log("Found - Saving login content to app storage");
      commit("saveLoginData", JSON.parse(downloaded));
    }
  },
  async blobToString({ commit, state }, blob) {
    const fileReader = new FileReader();
    return new Promise((resolve, reject) => {
      fileReader.onloadend = (ev) => {
        resolve(ev.target.result);
      };
      fileReader.onerror = reject;
      fileReader.readAsText(blob);
    });
  },
  async LogIn({ state, commit }, User) {
    var grantAccess = false;
    var userName = User.get("username").toLowerCase();
    var user = state.loginData.find((x) => x.Login == userName);

    if (user) {
      if (user.Password == User.get("password")) {
        grantAccess = true;
      }

      if (grantAccess) {
        await commit("setUser", userName);

        var t = new Date();
        t.setMonth(t.getMonth() + 3);

        var data = { user: userName, date: t };

        localStorage.setItem("login", JSON.stringify(data));
        return true;
      }
    }

    return false;
  },
  async validUser({ state, commit }) {
    var storage = localStorage.getItem("login");

    if (storage) {
      var data = JSON.parse(storage);
      var rightNow = new Date();

      if (Date.parse(data.date) > rightNow) {
        await commit("setUser", data.user);
        return true;
      } else {
        localStorage.removeItem("login");
        state.user = null;
      }
    }

    return false;
  },
};
