<template>
  <base-layout show-back-btn="true" page-default-back-link="/" show-info="true">
    <ion-text color="primary" class="ion-text-center title-headline">
      <h1>Søgning</h1>
    </ion-text>

    <ion-item class="result-search">
      <ion-icon :icon="icons.search" slot="start"></ion-icon>
      <ion-input
        type="tel"
        v-model="postnr"
        clear-on-edit="true"
        required
        @input="clearError()"
        v-on:keyup.enter="newSearch()"
      ></ion-input>
      <ion-button
        @click="newSearch()"
        slot="end"
        size="medium"
        color="white"
        shape="round"
      >
        <ion-icon
          :icon="icons.forward"
          slot="icon-only"
          color="primary"
        ></ion-icon>
      </ion-button>
    </ion-item>

    <ion-item-divider v-if="errorMessage.length > 0">
      <ion-label>
        {{ errorMessage }}
      </ion-label>
    </ion-item-divider>
    <ion-item class="" @click="showFilters = !showFilters">
      <ion-icon :icon="icons.filter" slot="start"></ion-icon>
      <ion-input readonly> Filtre </ion-input>
      <ion-icon :icon="chevron" class="chevron-icon"></ion-icon>
    </ion-item>
    <div class="filter-dropdown" v-if="showFilters">
      <ion-item>
        <ion-label for="Hund">
          <img class="filter-icon" :src="dogImgUrl" alt="" />
          <ion-text>Hund</ion-text>
        </ion-label>
        <ion-checkbox
          name="Hund"
          color="primary"
          v-model="filters.Hund"
        ></ion-checkbox>
      </ion-item>
      <ion-item>
        <ion-label for="Aspirant">
          <img class="filter-icon" :src="aspImgUrl" alt="" />
          <ion-text>Aspirant</ion-text>
        </ion-label>
        <ion-checkbox
          name="Aspirant"
          color="primary"
          v-model="filters.Aspirant"
        ></ion-checkbox>
      </ion-item>
      <ion-item>
        <ion-label for="Kommunal">
          <img class="filter-icon" :src="townImgUrl" alt="" />
          <ion-text>Kommunal</ion-text>
        </ion-label>
        <ion-checkbox
          name="Kommunal"
          color="primary"
          v-model="filters.Kommunal"
        ></ion-checkbox>
      </ion-item>
      <ion-item>
        <ion-label for="Skytte">
          <img class="filter-icon" :src="skytteImgUrl" alt="" />
          <ion-text>Skytte</ion-text>
        </ion-label>
        <ion-checkbox
          name="Skytte"
          color="primary"
          v-model="filters.Skytte"
        ></ion-checkbox>
      </ion-item>
      <ion-button fill="clear" @click="resetFilters" class="asLink">
        <ion-icon
          :icon="icons.close"
          slot="start"
          class="reset-icon"
        ></ion-icon>
        Nulstil
      </ion-button>
    </div>
    <ion-item v-if="warningMessage" color="warning">
      <ion-label>{{ warningMessage }}</ion-label>
    </ion-item>
    <div class="result-content">
      <div v-if="!listLoaded" class="spinner">
        <ion-spinner color="primary" name="bubbles"></ion-spinner>
      </div>
      <div v-else>
        <div v-if="postData && filteredList.length > 0">
          <div class="result-top">
            <ion-text>
              <h3>Resultat ({{ filteredList.length }})</h3>
            </ion-text>
            <ion-text>
              <ion-label v-if="postData">
                <ion-text color="primary">{{ postData.nr }}</ion-text> ({{
                  postData.navn
                }})
              </ion-label>
            </ion-text>
          </div>
          <ion-list>
            <person
              v-for="person in filteredList"
              :key="person.Initialer"
              :person="person"
            ></person>
          </ion-list>
        </div>
        <div v-else>
          <ion-card>
            <ion-item>
              <ion-card-content>
                <ion-text class="ion-text-center">
                  <ion-label>Intet resultat</ion-label>
                </ion-text>
              </ion-card-content>
            </ion-item>
          </ion-card>
        </div>
      </div>
    </div>
  </base-layout>
</template>

<script>
import { mapGetters } from "vuex";
import {
  informationCircle,
  search,
  close,
  filter,
  chevronDown,
  chevronUp,
  arrowForwardCircleOutline,
} from "ionicons/icons";
import {
  IonList,
  IonItem,
  IonItemGroup,
  IonLabel,
  IonInput,
  IonIcon,
  IonButton,
  IonText,
  IonSpinner,
  IonCheckbox,
  IonItemDivider,
  IonCard,
  IonCardContent,
} from "@ionic/vue";
import person from "../components/app/Person";
import zipData from "../../public/assets/data/zipcodes.json";

export default {
  components: {
    IonList,
    IonItem,
    IonItemGroup,
    IonLabel,
    IonInput,
    IonIcon,
    IonButton,
    IonText,
    person,
    IonSpinner,
    IonCheckbox,
    IonItemDivider,
    IonCard,
    IonCardContent,
  },
  computed: {
    ...mapGetters(["data", "dawaPost"]),
    chevron() {
      return this.showFilters ? this.icons.up : this.icons.down;
    },
    filteredList() {
      var app = this;
      this.listLoaded = false;

      setTimeout(() => {
        app.listLoaded = true;
      }, 200);
      const teknikere = this.data.filter((t) => t.Titel.toLowerCase() === "servicetekniker");
      if (this.postData && teknikere.length > 0) {
        for (let index = 0; index < teknikere.length; index++) {
          const element = this.data[index];
          element.distance = this.distance(
            element.y,
            element.x,
            this.postData.lat,
            this.postData.lng
          ).toFixed(0);
        }

        var list = teknikere;

        if (Object.values(this.filters).includes(true)) {
          list = teknikere.filter(function(item) {
            for (var key in app.filters) {
              if (item[key] === "x" && app.filters[key]) return true;
            }
            return false;
          });
        }

        return list.sort((a, b) => b.distance - a.distance).reverse();
      }
    },
    dogImgUrl() {
      return require("../../public/assets/icon/dog.png");
    },
    aspImgUrl() {
      return require("../../public/assets/icon/aspirant.png");
    },
    townImgUrl() {
      return require("../../public/assets/icon/town.png");
    },
    skytteImgUrl() {
      return require("../../public/assets/icon/skytte.png");
    },
    warningMessage() {
      // return this.postnr == "6950"
      //   ? "Der er i øjeblikket problemer med at udregne distancen til 6950 Ringkøbing - Brug 6940 Lem istedet."
      //   : "";
      return "";
    },
  },
  data() {
    return {
      icons: {
        info: informationCircle,
        search: search,
        close: close,
        filter: filter,
        down: chevronDown,
        up: chevronUp,
        forward: arrowForwardCircleOutline,
      },
      listLoaded: false,
      showFilters: false,
      postnr: this.$route.query.postnr,
      postData: null,
      errorMessage: "",
      filters: {
        Hund: false,
        Aspirant: false,
        Kommunal: false,
        Skytte: false,
      },
    };
  },
  mounted() {
    this.getPostData();
  },
  methods: {
    resetFilters() {
      for (var i in this.filters) {
        this.filters[i] = false;
      }
    },
    clearError() {
      this.errorMessage = "";
    },
    distance(lat1, lon1, lat2, lon2, unit = "K") {
      try {
        var radlat1 = (Math.PI * lat1) / 180;
        var radlat2 = (Math.PI * lat2) / 180;
        var theta = lon1 - lon2;
        var radtheta = (Math.PI * theta) / 180;
        var dist =
          Math.sin(radlat1) * Math.sin(radlat2) +
          Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
        dist = Math.acos(dist);
        dist = (dist * 180) / Math.PI;
        dist = dist * 60 * 1.1515;
        if (unit == "K") {
          dist = dist * 1.609344;
        }
        if (unit == "N") {
          dist = dist * 0.8684;
        }
        return dist;
      } catch (err) {
        console.log(err);
      }
    },
    getPostData(postData = null) {
      var app = this;
      this.listLoaded = false;

      setTimeout(() => {
        if (!postData) {
          postData = zipData.find((x) => x.nr == app.postnr);
        }
        if (postData != null) {
          app.postData = postData;
        }
        app.listLoaded = true;
      }, 500);
    },
    newSearch() {
      if (this.postnr.length == 4) {
        var dataPostnr = zipData.find((x) => x.nr == this.postnr);
        if (dataPostnr) {
          this.getPostData(dataPostnr);
        } else {
          this.errorMessage = "Postnummer findes ikke";
        }
      } else {
        this.errorMessage = "Udfyld korrekt postnummer";
      }
    },
  },
};
</script>
